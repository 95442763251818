import './SectionReviews.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const SectionReviewsPortuguese = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    };


    return (
        <>

            <div className="reviews-container">
                <Slider {...settings}>
                    <div>
                        <p className="intro">“Este seminário é para pessoas de qualquer idade”</p>
                        <p className="dr-norman">Noemi J Engler</p>
                    </div>

                    <div>
                        <p className="intro">“Fiquei impressionado com a claridade do Dr. Bentson!”</p>
                        <p className="dr-norman">Carlos Migues</p>
                    </div>

                    <div>
                        <p className="intro">“Finalmente compreendi meu trauma”</p>
                        <p className="dr-norman">Richard Vargas</p>
                    </div>

                    <div>
                        <p className="intro">“Sentir minhas emoções é um ato de coragem!”</p>
                        <p className="dr-norman">Alex Ambrosio</p>
                    </div>

                    <div>
                        <p className="intro">“Aprendemos a nos comunicar com empatia e de maneira profunda”</p>
                        <p className="dr-norman">Fernando e Mimi Sabatini</p>
                    </div>

                    <div>
                        <p className="intro">“Os conceitos e as práticas foram claras e muito efetivas””
                        </p>
                        <p className="dr-norman">Victoria e Juan Palacios</p>
                    </div>

                    <div>
                        <p className="intro">“O conceito do SER ORGÂNICO revolucionou meus paradigmas”</p>
                        <p className="dr-norman">Juan Carlos Durand</p>
                    </div>

                    <div>
                        <p className="intro">“Estas práticas deveriam ser ensinadas às crianças!”</p>
                        <p className="dr-norman">Flavia Schneider</p>
                    </div>

                    <div>
                        <p className="intro">“Biologia antes que psicologia, filosofia e religião? Que ideia tão lógica!”</p>
                        <p className="dr-norman">Benicio Paz</p>
                    </div>

                    <div>
                        <p className="intro">“As práticas nos ajudaram muito como casal”</p>
                        <p className="dr-norman">Elías e Mary Cruz</p>
                    </div>

                    <div>
                        <p className="intro">“Agora entendo que minhas emoções aprofundam minha vida espiritual!”</p>
                        <p className="dr-norman">Sarah Bustos</p>
                    </div>

                </Slider>
            </div>

        </>
    )
}

export default SectionReviewsPortuguese;