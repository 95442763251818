import './Home.css';
import { useState } from 'react';
import Toggle from '../Toggle/Toggle';
import Menu from '../Menu/Menu';
import Intro from '../Intro/Intro';
import SectionBiografia from '../SectionBiografia/SectionBiografia';
import ReviewsContainer from '../SectionReviews/SectionReviews';
import FiveModulesIntro from '../FiveModulesIntro/FiveModulesIntro';
import ModuloOne from '../ModuloOne/ModuloOne';
import ModuloTwo from '../ModuloTwo/ModuloTwo';
import ModuloThree from '../ModuloThree/ModuloThree';
import ModuloFour from '../ModuloFour/ModuloFour';
import ModuloFive from '../ModuloFive/ModuloFive';
import Footer from '../Footer/Footer';

const Home = () => {

    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
        setNavToggled(!navToggled);
    }

    return (
        <>
            <Toggle handleNavToggle={handleNavToggle} />
            {navToggled ? <Menu handleNavToggle={handleNavToggle} /> : null}

            <Intro />
            <ReviewsContainer />
            <SectionBiografia />
            <FiveModulesIntro />
            <ModuloOne />
            <ModuloTwo />
            <ModuloThree />
            <ModuloFour />
            <ModuloFive />
            <Footer />
        </>
    )
}


export default Home;