import './FiveModulesIntro.css'

const FiveModulesIntroEnglishPortuguese = () => {
    return (
        <>
            <div className="five-modules-container">

                <h2 className="title-five-modules text-center">UM SEMINÁRIO, 5 MÓDULOS </h2>
                <p className="content text-center breakline">FORMATO PRESENCIAL</p>
                <p className="content text-center breakline">PARA TODOS</p>
                <img className="eventbrite-intro" src="/images/ButonEvenbriteOrangePT.png" alt="" />
                <p className="content text-center breakline mt-35">“É hora de conectar-nos com nossa biologia,</p>
                <p className="content text-center breakline">de viver plenamente a partir desse encontro, </p> 
                <p className="content text-center breakline">de aprofundar com nosso ser e com o próximo”.</p>

                <img className="circle-img mt-35" src="/images/SelloPortugues.svg" alt="" />

            </div>
        </>
    )
}

export default FiveModulesIntroEnglishPortuguese;