import './ModuloFive.css';
import { useState } from 'react';


const ModuloFiveEnglish = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `The quality of our existence is determined by how we care emotionally for our intimate others. Deep listening is perhaps the best way to care. It is not easy to listen to each other, we get defensive and tend to focus on ourselves. Learning to listen requires clear concepts and effective practices.

Assuming Positive Intent

Seeing the Beauty in the Other

Honesty & Vulnerability

Communicating My Truth

Listen, Listen, Listen

Did I Get your Words, Emotions, and Pain?

Thank you for Listening Deeply

Are You Interested in My Perspective?

Practicing Generosity

Falling in Love
`;

    return (
        <>
            <div id="modulo-5">
            <img className="img-modulo" src="/images/english/modulo-5-desktop-en.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/english/modulo-5-mobile-en.webp" alt="" />

                <div className="container-text-modulo" id="modulo-5">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 298) + '...'}`}
                    </p>
                    <button className="btn-read-more orange-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "READ LESS" : "KEY CONCEPTS"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloFiveEnglish;