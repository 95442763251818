import './SectionReviews.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const SectionReviews = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    };


    return (
        <>

            <div className="reviews-container">
                <Slider {...settings}>
                    <div>
                        <p className="intro">“¡Me impresionó la llegada del Dr. Bentson!”</p>
                        <p className="dr-norman">Carlos Migues</p>
                    </div>

                    <div>
                        <p className="intro">“Finalmente comprendí mi trauma.”</p>
                        <p className="dr-norman">Richard Vargas</p>
                    </div>

                    <div>
                        <p className="intro">“Este semanario es para todas las edades”</p>
                        <p className="dr-norman">Noemi J Engler</p>
                    </div>

                    <div>
                        <p className="intro">“Los conceptos y las practicas fueron claras y muy efectivas”
                        </p>
                        <p className="dr-norman">Victoria y Juan Palacios</p>
                    </div>

                    <div>
                        <p className="intro">“¡Estas prácticas debería ensenarse a los niños!”</p>
                        <p className="dr-norman">Flavia Schneider</p>
                    </div>

                    <div>
                        <p className="intro">“Aprendimos a comunicarnos con empatía y profundidad”</p>
                        <p className="dr-norman">Fernando y Mimi Sabatini</p>
                    </div>

                    <div>
                        <p className="intro">“¡Sentir mis emociones es un acto de valentía!”</p>
                        <p className="dr-norman">Alex Ambrosio</p>
                    </div>

                    <div>
                        <p className="intro">“Ahora entiendo cómo construir un sentido de vida profundo”.</p>
                        <p className="dr-norman">Juan Carlos Durand</p>
                    </div>

                    <div>
                        <p className="intro">“El concepto del SER ORGANICO revolucionó mis paradigmas.”</p>
                        <p className="dr-norman">David J. Murphy</p>
                    </div>

                    <div>
                        <p className="intro">“Las practicas nos ayudaron mucho como pareja”.</p>
                        <p className="dr-norman">Elías y Mary Cruz</p>
                    </div>

                    <div>
                        <p className="intro">“Biología antes que psicología, filosofía y religión?¡Que idea tan lógica!”</p>
                        <p className="dr-norman">Benicio Paz</p>
                    </div>

                    <div>
                        <p className="intro">¡Ahora entiendo que mis emociones profundizan mi vida espiritual!”</p>
                        <p className="dr-norman">Sarah Bustos</p>
                    </div>


                </Slider>
            </div>

        </>
    )
}

export default SectionReviews;