import './ModuloFour.css';
import { useState } from 'react';


const ModuloFour = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `El cuidado emocional de uno mismo es nuestra primera responsabilidad. Es tiempo de aprender a cuidar nuestras emociones más intensas a través de la conexión directa con ellas, sin acudir al uso de defensas y creencias que nos disocian del sentir emocional; para esto necesitamos conceptos claros y practicas efectivas.
    
    EL CUIDADO EMOCIONAL DE UNO MISMO
    Herramientas Integrativas Individuales

    CONCEPTOS CLAVES: 

    Me Siento Fragmentado

    ¿Que Siento en Mi Cuerpo Ahora y Donde?

    Sintiendo por 45 Segundos

    La Respiración que Tranquiliza

    Manos que Acarician, Palabras que Sanan

    Enfrentando Mis Fallas Cerebrales

    Flexibilizando Mis Creencias

    Mi Voz Interna, Sabia y Consejera

    Escuchando Mis Múltiples Partes
    
    Exponiéndome a lo que Deseo
    `;


    return (
        <>
            <div id="modulo-4">
                <img className="img-modulo" src="/images/spanish/modulo4-desktop.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/spanish/modulo4-mobile.webp" alt="" />

                <div className="container-text-modulo" id="modulo-4">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 307) + '...'}`}
                    </p>
                    <button className="btn-read-more lightblue-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LEER MENOS" : "CONCEPTOS CLAVES"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloFour;