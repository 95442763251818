import './FiveModulesIntro.css'

const FiveModulesIntroEnglish = () => {
    return (
        <>
            <div className="five-modules-container">

                <h2 className="title-five-modules text-center">1 SEMINAR, 5 MODULES</h2>
                <p className="content text-center breakline">IN PERSON SEMINAR</p>
                <p className="content text-center breakline">FOR ALL AGES</p>
                <img className="eventbrite-intro" src="/images/ButonEvenbriteOrangeEN.png" alt="" />
                <p className="content text-center breakline mt-35">“It’s time to connect with our biology, </p>
                <p className="content text-center breakline">to live fully from such encounter,</p> 
                <p className="content text-center breakline">to go deeper within oneself and with the other.”</p>

                <img className="circle-img mt-35" src="/images/SelloIngles.svg" alt="" />

            </div>
        </>
    )
}

export default FiveModulesIntroEnglish;