import './ModuloOne.css';
import { useState } from 'react';


const ModuloOnePortuguese = () => {


    const [showMore, setShowMore] = useState(false);
    const textShowMore = `As emoções, ansiedade, defesas e crenças determinam nossa natureza e existir. A autenticidade e profundidade dependem de poder sentir nossas emoções, dependendo menos da ansiedade, defesas e crenças que nos dissociam delas; para conseguir isto necessitamos de conceitos claros e práticas efetivas.
    
CONCEITOS-CHAVE:

Identidade #1: Somos Seres Biológicos

Emoções, Ansiedade, Defesas, Crenças

10 Emoções Para Sentir

As 3 Funções da Ansiedade

Defesas que Dissociam 

Crenças Flexíveis x Crenças Rígidas

A Fragmentação Biológica

As Falhas Cerebrais

A Mente e suas Sub-Personalidades 

Sensações, Emoções e Sensualidade

O Amor como Construção Biológica
`;


    return (
        <>
            <div id="modulo-1">
            <img className="img-modulo" src="/images/portuguese/modulo-1-desktop-pt.webp" alt="Banner Module 1 Portuguese" />
                <img className="img-modulo-mobile" src="/images/portuguese/modulo-1-mobile-pt.webp" alt="Banner Module 1 Portuguese" />

                <div className="container-text-modulo" id="modulo-1">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 297) + '...'}`}
                    </p>
                    <button className="btn-read-more pink-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LER MENOS" : "CONCEITOS-CHAVE"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloOnePortuguese;