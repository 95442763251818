import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './App.css';
// components
import Home from './components/Home/Home';
import HomeEnglish from './components/Home/HomeEnglish';
import HomePortuguese from './components/Home/HomePortuguese';
// pages

function App() {

  return (
    <div className="App">

      <Helmet>
        <title>Escuchandonos - Seminario de Integración Psicológica</title>
        <meta name="description" content="La biología humana, la psicología de las emociones, del trauma, y de la existencia nos ofrecen un paradigma para vivir con mayor compasión, autenticidad, y profundidad." />

        <title>Escuchandonos - Seminario de Integración Psicológica</title>
        <meta name="description" content="La biología humana, la psicología de las emociones, del trauma, y de la existencia nos ofrecen un paradigma para vivir con mayor compasión, autenticidad, y profundidad." />
        <meta name="keywords" content="Psicológia, Seminario, Integración Psicológica, Terapia, Psicológo Clinico, Terapia Integrativa, Emociones, Traumas, Existencial, Realidades existenciales " />
        <meta name="language" content="es" />

        <meta property="og:title" content="Seminario de Integración Psicológica Escuchandonos" />
        <meta property="og:description" content="La biología humana, la psicología de las emociones, del trauma, y de la existencia nos ofrecen un paradigma para vivir con mayor compasión, autenticidad, y profundidad." />
        <meta property="og:image" content="https://www.escuchandonos.com/images/SelloES.jpg" />
        <meta property="og:url" content="https://escuchandonos.com" />

        <meta property="twitter:title" content="Seminario de Integración Psicológica Escuchandonos" />
        <meta property="twitter:description" content="La biología humana, la psicología de las emociones, del trauma, y de la existencia nos ofrecen un paradigma para vivir con mayor compasión, autenticidad, y profundidad." />
        <meta property="twitter:image" content="https://www.escuchandonos.com/images/SelloES.jpg" />
        <meta property="twitter:url" content="https://escuchandonos.com" />
      </Helmet>

      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/en" element={<HomeEnglish />} />
          <Route path="/pt" element={<HomePortuguese />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;