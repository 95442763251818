import './SectionBiografia.css'
import { useState } from 'react';

const SectionBiografiaPortuguese = () => {

    const [showMore, setShowMore] = useState(false);
    const text = ` O Dr. Bentson é um experiente psicólogo que trabalha com adultos, casais e idosos. Seu enfoque é empático e integrativo, colocando uma forte ênfase no tratamento das questões de fundo do ser humano: o emocional, traumático, relacional, cultural e existencial. 
    
    A orientação terapêutica do Dr. Bentson procura reestabelecer a conexão natural com a biologia humana, principalmente as emoções. A falta de conexão, a dissociação biológica-emocional, é o denominador comum de muitas problemáticas físicas e psicológicas. O Dr. Bentson integra esta orientação com importantes contribuições da neuropsicologia, da psicologia das emoções, do trauma, do apego; das terapias somáticas, psicodinâmicas, humanísticas, existenciais, cognitivas entre outras. A esta integração adiciona uma forte ênfase no uso de ferramentas terapêuticas que aceleram a recuperação e o crescimento. 

O Dr. Bentson trata problemáticas relacionadas com a ansiedade, depressão, o traumático e o relacional; os lutos, as questões existenciais, as transições da meia-idade, o divórcio, os traumas da religião, etc. O Dr. Bentson também trabalha com pacientes com doenças crônicas, psiquiátricas e com os processos da velhice e a morte.  

O Dr. Bentson passou por mudanças significativas em sua vida profissional, educacional, cultural, filosófica e relacional. Antes de dedicar-se à psicologia, transitou pelo mundo dos negócios, do marketing corporativo e empreendedor; a orientação vocacional para pessoas com deficiências físicas; e pelo mundo da teologia, das religiões e da espiritualidade. 

O Dr. Bentson cresceu na República Argentina; aos 30 anos emigrou para Los Ángeles, Califórnia (EUA) onde atualmente mora com sua companheira de vida, perto de seus quatro filhos adultos; viaja frequentemente para a Argentina e o resto da América Latina. 

O Dr. Bentson conta com uma mistura incomum de experiências que explicam a criatividade, integração, e praticidade que caracterizam este seminário.
`;

    return (
        <>
            <div className="bio-container" id="norman">
                <img className="circle-img" src="/images/dr-norman.png" alt="" />
                <h2 className="title-biografia subtitle text-center mt">Norman Raimundo Bentson</h2><br></br>
                <h3 className="text-center"><span className="span-h3">Psicólogo Clínico</span></h3><br></br>
                <h4 className="text-center"><span className="span-h4">Matrícula Califórnia 24747</span></h4><br />
                <h4 className="text-center"><span className="span-h4 text-grey">Doutorado em Psicologia Clínica (2010-EEUU)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Mestrado em Psicologia Transpessoal (2005-EEUU)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Mestrado Teologia e Estudos Transculturais (1990-EEUU)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Licenciado em Administração de Empresas (1980-ARGENTINA)</span></h4><br></br>



                <p className="content text-center breakline">
                    {showMore ? text : `${text.substring(0, 260)}`}
                </p>
                <button className="btn-read-more lightblue-btn mt-35" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "LER MENOS" : "LER MAIS"}
                </button>
            </div>

        </>
    )
}

export default SectionBiografiaPortuguese;