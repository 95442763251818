
import './Footer.css';

const FooterPortuguese = () => {
    return (
        <footer>
            <div className="eventbrite-container">
                <img className="eventbrite-footer" src="/images/EvenbritePortugues.png" alt="" />
            </div>
            <div className="footer-social-container" id="footer">
                <h2 className="social-title">PARA COMPARTILHAR</h2>
                <div className="social-icons-container">
                    <a href="https://wa.me/send?text=https://www.escuchandonos.com/" target="_blank" rel="noreferrer">
                        <img className="social-icons" src="/images/whatsapp.png" alt="" />
                    </a>
                    <a href="https://www.facebook.com/sharer.php?u=www.escuchandonos.com" target="_blank" rel="noreferrer">
                        <img className="social-icons" src="/images/fb.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/seminarioescuchandonos/" target="_blank" rel="noreferrer">
                        <img className="social-icons" src="/images/instagram.png" alt="" />
                    </a>
                    <a href="https://twitter.com/intent/tweet?url=www.escuchandonos.com" target="_blank" rel="noreferrer">
                        <img className="social-icons" src="/images/twitter.png" alt="" />
                    </a>
                    <a href="mailto:?subject=Compartiendo sitio web www.escuchandonos.com&amp;body=Te comparto el sitio web https://www.escuchandonos.com.">
                        <img className="social-icons" src="/images/mail.png" alt="" />
                    </a>
                </div>
                <img className="heart" src="/images/heart.png" alt="" />
                <div className="footer-social-container">
                    <h2 className="social-title">PARA NOS SEGUIR</h2>
                    <div className="social-icons-container">
                        <a href="https://www.facebook.com/people/Escuchandonos/100089137891865/" target="_blank" rel="noreferrer">
                            <img className="social-icons" src="/images/fb.png" alt="" />
                        </a>
                        <a href="https://www.instagram.com/seminarioescuchandonos/" target="_blank" rel="noreferrer">
                            <img className="social-icons" src="/images/instagram.png" alt="" />
                        </a>
                        <a href="https://twitter.com/_escuchandonos" target="_blank" rel="noreferrer">
                            <img className="social-icons" src="/images/twitter.png" alt="" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCtXVAPHgkrwuUO3tVEQh6fw" target="_blank" rel="noreferrer">
                        <img className="social-icons" src="/images/youtube.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="info-contact-container" id="contact">
                <img className="logo-ipi" src="/images/logo-ipi.svg" alt="" />
                <ul className="info-list">
                    <a href="https://www.google.com/maps/place/INTEGRATIVE-TELETHERAPY/@34.0950683,-117.7161319,15z/data=!4m5!3m4!1s0x0:0xe893111e523bb981!8m2!3d34.0950683!4d-117.7161319?hl=en" target="_blank"><li className="contact-info">112 Harvard Avenue # 3 <br></br> Claremont, California 91711</li></a>
                    <a href="https://wa.me/19094555831"><li className="contact-info">Whatsapp +1 (909) 455-5831</li></a>
                    <a href="mailto:drbentson@integrativepsychologyinc.com"><li className="contact-info">drbentson@integrativepsychologyinc.com</li></a>
                    <a href="http://www.integrativepsychologyinc.com" target="_blank" rel="noreferrer">
                        <li className="contact-info">www.integrativepsychologyinc.com</li>
                    </a>
                </ul>

            </div>


        </footer>
    )
}

export default FooterPortuguese;