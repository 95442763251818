import './Intro.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Intro = () => {
    return (
        <>
            <div className="intro-container">
                <div class="half-circle">
                    <div className="circle-content">
                        <p className="circle-text-one">100% HUMANO</p>
                        <p className="circle-text-two">¿Deseas Vivir Así?</p>
                    </div>
                </div>

                <img className="logo-intro" src="/images/LogoES.png" alt="" />
                <p className="intro">“La biología humana, la psicología de las emociones, del trauma, y de la existencia nos ofrecen un paradigma para vivir con mayor compasión, autenticidad y profundidad”.</p>
                <p className="dr-norman">Dr. Norman Raimundo Bentson</p>
                <div className="container-circles intro-circles-grid">
                    <HashLink to="#modulo-1"><img className="modules-circles" src="/images/mod1.webp" alt="" /></HashLink>
                    <HashLink to="#modulo-2"><img className="modules-circles" src="/images/mod2.webp" alt="" /></HashLink>
                    <HashLink to="#modulo-3"><img className="modules-circles" src="/images/mod3.webp" alt="" /></HashLink>
                    <HashLink to="#modulo-4"><img className="modules-circles" src="/images/mod4.webp" alt="" /></HashLink>
                    <HashLink to="#modulo-5"><img className="modules-circles" src="/images/mod-5.webp" alt="" /></HashLink>
                </div>
                <img className="eventbrite-intro" src="/images/ButonEvenbriteOrange.png" alt="" />
                <div className="lang-links-container-intro">
                    <Link className="lang-items-intro" to="/">ESPAÑOL</Link>
                    <Link className="lang-items-intro" to="/pt">PORTUGUÊS</Link>
                    <Link className="lang-items-intro" to="/en">ENGLISH</Link>
                </div>
            </div>

        </>
    )
}

export default Intro;