import './ModuloFive.css';
import { useState } from 'react';


const ModuloFivePortuguese = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `A maneira como cuidamos o ser íntimo determina a qualidade da nossa existência. É hora de aprender a cuidar do próximo, emocionalmente, através do incentivo da escuta profunda. Não é fácil escutar-nos; tendemos a colocar-nos na defensiva e enfocar-nos em nós mesmos; necessitamos conceitos claros e práticas efetivas.
    
    FERRAMENTAS-CHAVE
    
A Intenção Positiva 

Ver a Beleza do Outro

Vulnerabilidade e Sinceridade 

Comunicando Minha Verdade

Escute, Escute, Escute

Entendi suas Palavras, Emoções e Dor?

Obrigado por Me Escutar

Te Interessa Escutar Minha Perspectiva?

Praticando a Generosidade

`;

    return (
        <>
            <div id="modulo-5">
                <img className="img-modulo" src="/images/portuguese/modulo-5-desktop-pt.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/portuguese/modulo-5-mobile-pt.webp" alt="" />

                <div className="container-text-modulo" id="modulo-5">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 316) + '...'}`}
                    </p>
                    <button className="btn-read-more orange-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LER MENOS" : "CONCEITOS-CHAVE"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloFivePortuguese;