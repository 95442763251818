import './ModuloFour.css';
import { useState } from 'react';


const ModuloFourPortuguese = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `O cuidado emocional de nós mesmos é a nossa principal responsabilidade. É hora de aprender a cuidar de nossas emoções mais intensas através da conexão direta com elas, sem acudir ao uso de defesas e crenças que nos dissociam do sentir emocional; para isto necessitamos de conceitos claros e práticas efetivas.
    
    FERRAMENTAS-CHAVE: 

    Me Sinto Fragmentado

    O Que Sinto no Meu Corpo Agora e Onde?

    Sentindo por 45 Segundos

    A Respiração que Tranquiliza

    Mãos que Acariciam, Palavras que Curam

    Enfrentando Minhas Falhas Cerebrais

    Flexibilizando Minhas Crenças

    Minha Voz Interna, Sábia e Conselheira

    Escutando Minhas Múltiplas Partes
    
    Expondo-me ao que Desejo

    `;


    return (
        <>
            <div id="modulo-4">
                <img className="img-modulo" src="/images/portuguese/modulo-4-desktop-pt.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/portuguese/modulo-4-mobile-pt.webp" alt="" />

                <div className="container-text-modulo" id="modulo-4">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 308) + '...'}`}
                    </p>
                    <button className="btn-read-more lightblue-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LER MENOS" : "CONCEITOS-CHAVE"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloFourPortuguese;