import './ModuloOne.css';
import { useState } from 'react';


const ModuloOne = () => {


    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Las emociones, ansiedad, defensas y creencias definen nuestra naturaleza y existir. La autenticidad y profundidad dependen de poder sentir nuestras emociones, dependiendo menos de la ansiedad, defensas y creencias que nos disocian de ellas; para logar esto necesitamos conceptos claros y practicas efectivas. 
    
    REALIDADES BIOLÓGICAS DE NUESTRA NATURALEZA
Conceptos Claves

Identidad # 1: Somos Seres Biológicos

Emociones, Ansiedad, Defensas, Creencias

10 Emociones Para Sentir

Las 3 Funciones de la Ansiedad

Defensas que Disocian 

Creencias Flexibles vs Creencias Rígidas

La Fragmentación Biológica

Las Fallas Cerebrales

La Mente y sus Sub-Personalidades 

Sensaciones, Emociones y Sensualidad

El Amor como Construcción Biológica`;


    return (
        <>
            <div id="modulo-1">
                <img className="img-modulo" src="/images/spanish/modulo1-desktop.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/spanish/modulo1-mobile.webp" alt="" />

                <div className="container-text-modulo">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 308) + '...'}`}
                    </p>
                    <button className="btn-read-more pink-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LEER MENOS" : "CONCEPTOS CLAVES"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloOne;