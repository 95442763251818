import './SectionBiografia.css'
import { useState } from 'react';

const SectionBiografiaEnglish = () => {

    const [showMore, setShowMore] = useState(false);
    const text = ` Dr. Bentson is an experienced psychologist who works with adults, couples, and the elderly. His orientation is integrative and empathic; his therapeutic approach seeks the treatment of underlying issues related to  emotional, traumatic, relational, cultural, and existential realities. 
    
    Dr. Bentson’s therapeutic orientation seeks to reestablish the organic connection with all aspects of our biology, principally our emotions. The lack of connection, the biological dissociation, is the common denominator of many psychological and physical issues. Dr. Bentson integrates this approach with important contributions of neuropsychology, the psychology of emotions, trauma, attachment, somatic therapies, psychodynamic, humanistic, existential, cognitive, and more. To all this he adds a strong emphasis on the practice of therapeutic tools that can speed recovery and growth.

Dr. Bentson treats issues relating to anxiety, depression, trauma, grief, relational issues, existential issues, mid-life, divorce, religious trauma, etc. Dr. Bentson also works with patients experiencing chronic medical and psychiatric conditions, the aging process, and issues related to death.
Dr. Bentson has experienced significant changes in his professional, educational cultural, philosophical, and relational life. Prior to becoming a psychologist, he worked in the corporate and entrepreneurial marketing worlds; as a vocational rehabilitation consultant for people with physical disabilities; and in the fields of theology, religions, and spirituality.

 He was raised in Buenos Aires, Argentina; at age 30 he emigrated to Los Angeles, California where he currently resides with his life partner and close to his four adult children.  He travels frequently to Argentina and to the rest of Latin America. 

Dr. Bentson has experienced an interesting mix of experiences that explain the creativity, integration, and practicality that characterizes this seminar.`;

    return (
        <>
            <div className="bio-container" id="norman">
                <img className="circle-img" src="/images/dr-norman.png" alt="" />
                <h2 className="title-biografia subtitle text-center mt">Norman Raimundo Bentson</h2><br></br>
                <h3 className="text-center"><span className="span-h3">Clinical Psychologist</span></h3><br></br>
                <h4 className="text-center"><span className="span-h4">California License 24747</span></h4><br />
                <h4 className="text-center"><span className="span-h4 text-grey">Doctorate Clinical Psychology (2010-USA)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Masters Transpersonal Psychology (2005-USA)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Masters Theology & Cross-Cultural Studies (1990-USA)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Bachelors Business Administration (1980-ARGENTINA)</span></h4><br></br>



                <p className="content text-center breakline">
                    {showMore ? text : `${text.substring(0, 286)}`}
                </p>
                <button className="btn-read-more lightblue-btn mt-35" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "READ LESS" : "READ MORE"}
                </button>
            </div>

        </>
    )
}

export default SectionBiografiaEnglish;