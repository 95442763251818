import './ModuloTwo.css';
import { useState } from 'react';


const ModuloTwoPortuguese = () => {


    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Todos sofremos traumas e lutos que afetam nossas emoções, ansiedade, defesas e crenças. Também afetam a relação com nós mesmos, com o próximo e com a vida. Gradativamente podemos curar efeitos do trauma e do luto; para isto necessitamos conceitos claros e práticas efetivas.
    
CONCEITOS-CHAVE:

As Emoções Principais do Trauma

O Trauma e as 3 Funções da Ansiedade

O Trauma e a Capacidade do Sentir

Defesas Principais do Trauma

O Luto Sempre está Presente

Expondo-me ao Sentir Emocional

Ferramentas para Curar o Trauma e o Luto

Expondo-me ao que Desejo e Temo

Quando Procurar um Profissional
`;

    return (
        <>
            <div id="modulo-2">
            <img className="img-modulo" src="/images/portuguese/modulo-2-desktop-pt.webp" alt="Banner Module 2 Portuguese" />
                <img className="img-modulo-mobile" src="/images/portuguese/modulo-2-mobile-pt.webp" alt="Banner Module 2 Portuguese" />

                <div className="container-text-modulo" id="modulo-2">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 273) + '...'}`}
                    </p>
                    <button className="btn-read-more purple-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LER MENOS" : "CONCEITOS-CHAVE"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloTwoPortuguese;