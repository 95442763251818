import './ModuloThree.css';
import { useState } from 'react';


const ModuloThreeEnglish = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Existential and cultural realities are the DNA of existence and affect our emotions, anxiety, defenses, and beliefs. The depth with which we live depends on our comprehension and connection with each reality; for this we need clear concepts and effective practices.
    

Identity # 2: We are Existential, and Cultural Beings

Emotional Feeling, Less Defenses & Beliefs

Existential Realities that Limit

 Reality & Life Meaning

The Unconscious Worldview

Cultural Realities to Love and to Confront

Culture and Spirituality Rooted in Biology

Profound Biological-Emotional Experiences

Rituals for the XXI Century 

`;

    return (
        <>
            <div id="modulo-3">
            <img className="img-modulo" src="/images/english/modulo-3-desktop-en.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/english/modulo-3-mobile-en.webp" alt="" />

                <div className="container-text-modulo" id="modulo-3">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 265) + '...'}`}
                    </p>
                    <button className="btn-read-more yellow-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "READ LESS" : "KEY CONCEPTS"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloThreeEnglish;