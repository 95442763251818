import './FiveModulesIntro.css'

const FiveModulesIntro = () => {
    return (
        <>
            <div className="five-modules-container">

                <h2 className="title-five-modules text-center">UN SEMINARIO, 5 MÓDULOS</h2>
                <p className="content text-center breakline">FORMATO PRESENCIAL</p>
                <p className="content text-center breakline">PARA TODO PUBLICO</p>
                <img className="eventbrite-intro" src="/images/ButonEvenbriteOrange.png" alt="" />
                <p className="content text-center breakline mt-35">“Es tiempo de conectarnos con nuestra biología, </p>
                <p className="content text-center breakline">de vivir plenamente desde dicho encuentro,</p> 
                <p className="content text-center breakline">de profundizar con uno mismo y con el prójimo”.</p>

                <img className="circle-img mt-35" src="/images/SelloES.png" alt="" />

            </div>
        </>
    )
}

export default FiveModulesIntro;