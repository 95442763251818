import { useState } from 'react';
import { Helmet } from "react-helmet";
import Toggle from '../Toggle/Toggle';
import MenuEnglish from '../Menu/MenuEnglish';

import IntroEnglish from "../Intro/IntroEnglish";
import SectionReviewsEnglish from '../SectionReviews/SectionReviewsEnglish';
import SectionBiografiaEnglish from '../SectionBiografia/SectionBiografiaEnglish';
import FiveModulesIntroEnglish from '../FiveModulesIntro/FiveModulesIntroEnglish';
import ModuloOneEnglish from '../ModuloOne/ModuloOneEnglish';
import ModuloTwoEnglish from '../ModuloTwo/ModuloTwoEnglish';
import ModuloThreeEnglish from '../ModuloThree/ModuloThreeEnglish';
import ModuloFourEnglish from '../ModuloFour/ModuloFourEnglish';
import ModuloFiveEnglish from '../ModuloFive/ModuloFiveEnglish';
import FooterEnglish from '../Footer/FooterEnglish';

const HomeEnglish = () => {

    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
        setNavToggled(!navToggled);
    }

    return (
        <>
            <Helmet>
                <title>The Organic Self - Integrative Psychology Seminars</title>
                <meta name="description" content="Human biology, the psychology of emotions, trauma, and existence offer us a paradigm to live with increased compassion, authenticity, and depth." />
                <meta name="keywords" content="Psicológia, Seminario, Integración Psicológica, Terapia, Psicológo Clinico, Terapia Integrativa, Emociones, Traumas, Existencial, Realidades existenciales " />
                <meta name="language" content="en" />
            </Helmet>

            <Toggle handleNavToggle={handleNavToggle} />
            {navToggled ? <MenuEnglish handleNavToggle={handleNavToggle} /> : null}

            <IntroEnglish />
            <SectionReviewsEnglish />
            <SectionBiografiaEnglish />
            <FiveModulesIntroEnglish />
            <ModuloOneEnglish />
            <ModuloTwoEnglish />
            <ModuloThreeEnglish />
            <ModuloFourEnglish />
            <ModuloFiveEnglish />
            <FooterEnglish />

        </>
    )
}

export default HomeEnglish;