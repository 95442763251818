import './SectionBiografia.css'
import { useState } from 'react';

const SectionBiografia = () => {

    const [showMore, setShowMore] = useState(false);
    const text = ` El Dr. Bentson es un experimentado psicólogo que trabaja con adultos, parejas y ancianos. Su enfoque es empático e integrativo; cultiva un fuerte énfasis en el tratamiento de cuestiones de fondo del ser humano: lo emocional, traumático, relacional, cultural y existencial.
    
    La orientación terapéutica del Dr. Bentson busca reestablecer la conexión natural con la biología humana, principalmente las emociones. La falta de conexión, la disociación biológica-emocional, es el común denominador de muchas problemáticas físicas y psicológicas. El Dr. Bentson integra esta orientación con aportes importantes de la neuropsicología, la psicología de las emociones, del trauma, del apego; de las terapias somáticas, psicodinámicas, humanísticas, existenciales, cognitivas y otras. A esta integración le agrega un fuerte énfasis en el uso de herramientas terapéuticas que aceleran la recuperación y el crecimiento. 

    El Dr Bentson trata problemáticas relacionadas a la ansiedad, depresión, lo traumático y relacional; los duelos, los temas existenciales, las transiciones de la mediana edad, el divorcio, los traumas de la religión, etc. El Dr. Bentson también trabaja con pacientes con enfermedades crónicas, psiquiátricas y con los procesos de la vejez y la muerte.

    El Dr. Bentson ha experimentado cambios significativos en su vida profesional, educacional, cultural, filosófico y relacional. Antes de dedicarse a la psicología, transitó el mundo de los negocios, del marketing corporativo y emprendedor; la orientación vocacional para personas con discapacidades físicas; y el mundo de la teología, las religiones y espiritualidad. 

    El Dr. Bentson se crio en la República Argentina; a los 30 años emigró a Los Ángeles, California (EEUU) donde actualmente reside con su compañera de vida, cerca de sus cuatro hijos adultos; viaja asiduamente a la Argentina y al resto de América Latina. 

    El Dr. Bentson cuenta con una mezcla inusual de experiencias que explican la creatividad, integración, y practicidad que caracteriza este seminario.`;

    return (
        <>
            <div className="bio-container" id="norman">
                <img className="circle-img" src="/images/dr-norman.png" alt="" />
                <h2 className="title-biografia subtitle text-center mt">Norman Raimundo Bentson</h2><br></br>
                <h3 className="text-center"><span className="span-h3">Psicólogo Clínico</span></h3><br></br>
                <h4 className="text-center"><span className="span-h4">Matricula California 24747</span></h4><br />
                <h4 className="text-center"><span className="span-h4 text-grey">Doctorado en Psicología Clínica (2010-EEUU)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Maestría en Psicología Transpersonal (2005-EEUU)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Maestría Teología y Estudios Transculturales (1990-EEUU)</span></h4>
                <h4 className="text-center"><span className="span-h4 text-grey">Licenciado Administración de Empresas (1980-ARGENTINA)</span></h4><br></br>



                <p className="content text-center breakline">
                    {showMore ? text : `${text.substring(0, 273)}`}
                </p>
                <button className="btn-read-more lightblue-btn mt-35" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "VER MENOS" : "LEER MÁS"}
                </button>
            </div>

        </>
    )
}

export default SectionBiografia;