import './ModuloTwo.css';
import { useState } from 'react';


const ModuloTwo = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Todos hemos sufrido traumas y duelos que afectan nuestras emociones, ansiedad, defensas y creencias. También afectan la relación con nosotros mismos, con el prójimo y con la vida. Gradualmente podemos sanar efectos del trauma y del duelo; para esto necesitamos conceptos claros y prácticas efectivas.
    
    REALIDADES BIOLÓGICAS DEL TRAUMA Y DUELO
Conceptos Claves

Las Emociones Principales del Trauma

El Trauma y las 3 Funciones de la Ansiedad

El Trauma y la Capacidad del Sentir

Defensas Principales del Trauma

EL Duelo Siempre está Presente

Exponiéndome al Sentir Emocional

Herramientas para Sanar el Trauma y Duelo

Exponiéndome a lo que Deseo y Temo

Cuando Acudir a un Profesional

`;

    return (
        <>
            <div id="modulo-2">
                <img className="img-modulo" src="/images/spanish/modulo2-desktop.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/spanish/modulo2-mobile.webp" alt="" />

                <div className="container-text-modulo" id="modulo-2">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 298) + '...'}`}
                    </p>
                    <button className="btn-read-more purple-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LEER MENOS" : "CONCEPTOS CLAVES"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloTwo;