import './ModuloOne.css';
import { useState } from 'react';


const ModuloOneEnglish = () => {


    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Emotions, anxiety, defenses, and beliefs define our nature and existence. The authenticity and depth with which we live depend on experiencing our emotions, depending less on anxiety, defenses, and beliefs that dissociate us from them; for this we need clear concepts and effective practices.
    
Identity # 1: We are Biological Beings

Emotions, Anxiety, Defenses, Beliefs

10 Emotions to Feel

The 3 Functions of Anxiety

Defenses that Dissociate

Flexible Beliefs vs Rigid Beliefs

Biological Fragmentations

The Reality of Brain Faults

The Mind and its Sub-Personalities

Sensations, Emotions, and Sensuality

Love as a Biological Construction
`;


    return (
        <>
            <div id="modulo-1">
            <img className="img-modulo" src="/images/english/modulo-1-desktop-en.webp" alt="Banner Module 1 English" />
                <img className="img-modulo-mobile" src="/images/english/modulo-1-mobile-en.webp" alt="Banner Module 1 English" />

                <div className="container-text-modulo" id="modulo-1">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 291) + '...'}`}
                    </p>
                    <button className="btn-read-more pink-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "READ LESS" : "KEY CONCEPTS"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloOneEnglish;