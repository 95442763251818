import './ModuloThree.css';
import { useState } from 'react';


const ModuloThree = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Las realidades existenciales y culturales de la vida son el ADN de la existencia y afectan nuestras emociones, ansiedad, defensas y  creencias. La profundidad con la cual vivimos depende de nuestra comprensión y conexión con cada realidad; para lograr esto necesitamos conceptos claros y practicas efectivas.

    REALIDADES EXISTENCIALES y CULTURALES DE LA VIDA
Conceptos Claves:

Identidad #2: Somos Seres Existenciales y Culturales

Mas Sentir Emocional, Menos Defensas y Creencias

Realidades Existenciales Que Nos Limitan

La Realidad y el Sentido de Vida

La Cosmovisión Inconsciente

Realidades Culturales para Amar y Repudiar

Cultura y Espiritualidad Enraizada en la Biología

La Experiencia Biológica-Emocional Profunda

`;

    return (
        <>
            <div id="modulo-3">
                <img className="img-modulo" src="/images/spanish/modulo3-desktop.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/spanish/modulo3-mobile.webp" alt="" />

                <div className="container-text-modulo" id="modulo-3">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 307) + '...'}`}
                    </p>
                    <button className="btn-read-more yellow-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LEER MENOS" : "CONCEPTOS CLAVES"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloThree;