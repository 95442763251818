import './ModuloFour.css';
import { useState } from 'react';


const ModuloFourEnglish = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Carrying for ourselves emotionally is our first responsibility. Its time to learn how to care for our intense emotions by experiencing a direct connection with them, relying less on defenses and beliefs that dissociate us from our feelings; for this we need clear concepts and effective practices.
    
    I feel Fragmented

    What Am I feeling in My Body Now & Where?
    
    Feeling for 45 Seconds
    
    Feeling the Breath that Soothes
    
    Hands that Soothe, Words that Heal
    
    Confronting My Brain Faults
    
    Rigid to Flexible Beliefs
    
    My Internal & Wise Voice
    
    Listening to My Multiple Parts
    
    Exposure Towards What I Desire
`;


    return (
        <>
            <div id="modulo-4">
                <img className="img-modulo" src="/images/english/modulo-4-desktop-en.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/english/modulo-4-mobile-en.webp" alt="" />

                <div className="container-text-modulo" id="modulo-4">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 296) + '...'}`}
                    </p>
                    <button className="btn-read-more lightblue-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "READ LESS" : "KEY CONCEPTS"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloFourEnglish;