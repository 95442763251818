import './Menu.css';


import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { CgClose } from "react-icons/cg";

const MenuPortuguese = ({ handleNavToggle }) => {
    return (
        <StyledMenu>
            <ContainerLinks>
                <StyledLink className="animate__animated animate__fadeInRight" onClick={handleNavToggle} to="/pt#norman">Dr. Norman R. Bentson</StyledLink>
                <StyledLink className="animate__animated animate__fadeInRight" onClick={handleNavToggle} to="/pt#modulo-1">Módulo 1: <br /> Realidades Biológicas de Nossa Natureza</StyledLink>
                <StyledLink className="animate__animated animate__fadeInRight" onClick={handleNavToggle} to="/pt#modulo-2">Módulo 2: <br /> Realidades Biológicas do Trauma e Luto</StyledLink>
                <StyledLink className="animate__animated animate__fadeInRight" onClick={handleNavToggle} to="/pt#modulo-3">Módulo 3: <br /> Realidades Existenciais e Culturais da Vida </StyledLink>
                <StyledLink className="animate__animated animate__fadeInRight" onClick={handleNavToggle} to="/pt#modulo-4">Módulo 4: <br /> Ferramentas Integrativas Individuais </StyledLink>
                <StyledLink className="animate__animated animate__fadeInRight" onClick={handleNavToggle} to="/pt#modulo-5">Módulo 5: <br /> Ferramentas Integrativas Relacionais</StyledLink>
                <StyledLink className="animate__animated animate__fadeInRight border-bottom" onClick={handleNavToggle} to="#footer">Redes sociais e contato</StyledLink>
                <img className="eventbrite-img" src="/images/EvenbritePortugues.png" alt="" />
                <div className="lang-links-container">
                    <Link className="lang-items" to="/">ESPANHOL</Link>
                    <Link className="lang-items" to="/pt">PORTUGUÊS</Link>
                    <Link className="lang-items" to="/en">INGLÊS</Link>
                </div>
            </ContainerLinks>

            <CloseToggle className="animate__animated animate__fadeInRight" onClick={handleNavToggle}><CgClose /></CloseToggle>
        </StyledMenu>
    )
}


const ContainerLinks = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 225px;
`;

const StyledMenu = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    @media screen and (min-width: 790px) {
        width: 100%;
    }
    background-color: #ffffff;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledLink = styled(HashLink)`
    color: #333;
    text-decoration: none;
    font-size: 18px;
    font-family: 'ApexNew Medium';
    text-align: center; 
    border-bottom: 3px solid #80CAD6;
    padding: 15px 10px 10px 10px;    
    width: 100%;
    transition: .2s all ease-in-out;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
    &:hover {
        transition: .2s all ease-in-out;
        color: #333;
    }
    @media screen and (max-width: 790px) {
        line-height: 1.4em;
    }
`;

const CloseToggle = styled.button`
    position: fixed;
    top: 5%;
    right: 4%;
    background: #80CAD6;
    color: #fff;
    padding: .75rem;
    border-radius: 30px;
    display: flex;
    place-items: center;
    font-size: 2rem;
    cursor: pointer;
    border: none !important;
`;

export default MenuPortuguese