import './ModuloTwo.css';
import { useState } from 'react';


const ModuloTwoEnglish = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `We’ve all suffered trauma and grief that affect our emotions, anxiety, defenses, and beliefs. They also affect the relationship with ourselves, with others, and with life. We can gradually the heal effects of trauma and grief; for this we need clear concepts and effective practices.
    
Trauma’s Main Emotions

Trauma & The 3 Functions of Anxiety

Trauma and Our Capacity to Feel

The Main Defenses of Trauma

Always Grieving Something

Exposure to Emotional Feeling

Tools to Heal Trauma & Grief

Exposure to What I Desire & Fear

When to Get Professional Help


`;

    return (
        <>
            <div id="modulo-2">
                <img className="img-modulo" src="/images/english/modulo-2-desktop-en.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/english/modulo-2-mobile-en.webp" alt="" />

                <div className="container-text-modulo" id="modulo-2">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 282) + '...'}`}
                    </p>
                    <button className="btn-read-more purple-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "READ LESS" : "KEY CONCEPTS"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloTwoEnglish;