import { useState } from 'react';
import { Helmet } from "react-helmet";
import Toggle from '../Toggle/Toggle';
import MenuPortuguese from '../Menu/MenuPortuguese';

import IntroPortuguese from "../Intro/IntroPortuguese";
import SectionReviewsPortuguese from '../SectionReviews/SectionReviewsPortuguese';
import SectionBiografiaPortuguese from '../SectionBiografia/SectionBiografiaPortuguese';
import FiveModulesIntroEnglishPortuguese from '../FiveModulesIntro/FiveModulesIntroPortuguese';
import ModuloOnePortuguese from '../ModuloOne/ModuloOnePortuguese';
import ModuloTwoPortuguese from '../ModuloTwo/ModuleTwoPortuguese';
import ModuloThreePortuguese from '../ModuloThree/ModuleThreePortuguese';
import ModuloFourPortuguese from '../ModuloFour/ModuloFourPortuguese';
import ModuloFivePortuguese from '../ModuloFive/ModuloeFivePortuguese';
import FooterPortuguese from '../Footer/FooterPortuguese';

const HomePortuguese = () => {

    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
        setNavToggled(!navToggled);
    }

    return (
        <>
            <Helmet>
                <title>ESCUTANDO-NOS | Seminário de Integração Psicológica</title>
                <meta name="description" content="A biologia humana, a psicologia das emoções, do trauma, e da existência nos apresentam um paradigma para viver com mais compaixão, autenticidade e profundidade." />
                <meta name="keywords" content="Psicológia, Seminario, Integración Psicológica, Terapia, Psicológo Clinico, Terapia Integrativa, Emociones, Traumas, Existencial, Realidades existenciales " />
                <meta name="language" content="pt" />
            </Helmet>


            <Toggle handleNavToggle={handleNavToggle} />
            {navToggled ? <MenuPortuguese handleNavToggle={handleNavToggle} /> : null}

            <IntroPortuguese />
            <SectionReviewsPortuguese />
            <SectionBiografiaPortuguese />
            <FiveModulesIntroEnglishPortuguese />
            <ModuloOnePortuguese />
            <ModuloTwoPortuguese />
            <ModuloThreePortuguese />
            <ModuloFourPortuguese />
            <ModuloFivePortuguese />
            <FooterPortuguese />
        </>
    )
}

export default HomePortuguese;