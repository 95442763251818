import './ModuloFive.css';
import { useState } from 'react';


const ModuloFive = () => {

    const [showMore, setShowMore] = useState(false);
    const textShowMore = `Como cuidamos al ser intimo define la calidad de nuestra existencia. Es tiempo de aprender a cuidar al prójimo, emocionalmente, a través del fomento de la escucha profunda. No es fácil escucharnos; tendemos a ponernos defensivos y enfocarnos en nosotros mismos; necesitamos conceptos claros y practicas efectivas.
    
                        EL CUIDADO EMOCIONAL DEL OTRO                            
Herramientas Integrativas Relacionales  

CONCEPTOS CLAVES:
    
La Intención Positiva

La Belleza del Otro

Practicando Vulnerabilidad y Sinceridad

Comunicando Mi Verdad

Escuchá, Escuchá, Escuchá

¿Entendí tus Palabras, Emociones y Dolor?

Gracias por Escucharme

¿Te Interesa Escuchar Mi Perspectiva?

Practicando la Generosidad
`;

    return (
        <>
            <div id="modulo-5">
                <img className="img-modulo" src="/images/spanish/modulo5-desktop.webp" alt="" />
                <img className="img-modulo-mobile" src="/images/spanish/modulo5-mobile.webp" alt="" />

                <div className="container-text-modulo" id="modulo-5">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 312) + '...'}`}
                    </p>
                    <button className="btn-read-more orange-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LEER MENOS" : "CONCEPTOS CLAVES"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloFive;