import './ModuloThree.css';
import { useState } from 'react';


const ModuloThreePortuguese = () => {


    const [showMore, setShowMore] = useState(false);
    const textShowMore = `As realidades existenciais e culturais da vida são o ADN da existência e afetam nossas emoções, ansiedade, defesas e crenças. A profundidade com a qual vivemos depende da nossa compreensão e conexão com cada realidade; para conseguir isto necessitamos de conceitos claros e práticas efetivas.
    
CONCEITOS-CHAVE:

Identidade # 2: Somos Seres Existenciais e Culturais

Mais Sentir Emocional, Menos Defesas e Crenças

Realidades Existenciais Que Nos Limitam

A Realidade e o Sentido da Vida

A Cosmovisão Inconsciente

Realidades Culturais para Amar e Repudiar

Cultura e Espiritualidade Enraizada na Biologia

A Experiência Biológica-Emocional Profunda
`;

    return (
        <>
            <div id="modulo-3">
            <img className="img-modulo" src="/images/portuguese/modulo-3-desktop-pt.webp" alt="Banner Module 2 Portuguese" />
                <img className="img-modulo-mobile" src="/images/portuguese/modulo-3-mobile-pt.webp" alt="Banner Module 2 Portuguese" />

                <div className="container-text-modulo" id="modulo-3">
                    <p className="content breakline">
                        {showMore ? textShowMore : `${textShowMore.substring(0, 291) + '...'}`}
                    </p>
                    <button className="btn-read-more yellow-btn mt-35" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "LER MENOS" : "CONCEITOS-CHAVE"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default ModuloThreePortuguese;