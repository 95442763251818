import './SectionReviews.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const SectionReviewsEnglish = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    };


    return (
        <>

            <div className="reviews-container">
                <Slider {...settings}>
                    <div>
                        <p className="intro">“This seminar is for people of all ages”</p>
                        <p className="dr-norman">Noemi J Engler</p>
                    </div>

                    <div>
                        <p className="intro">“I was impressed by Dr. Bentson humanity”</p>
                        <p className="dr-norman">Carlos Migues</p>
                    </div>

                    <div>
                        <p className="intro">“This material should be taught to children.”</p>
                        <p className="dr-norman">Eva  Schneider</p>
                    </div>

                    <div>
                        <p className="intro">“I understood my trauma.”</p>
                        <p className="dr-norman">Richard Vargas</p>
                    </div>

                    <div>
                        <p className="intro">“Feeling my emotions is an act of courage!”</p>
                        <p className="dr-norman">Alex Ambrosio</p>
                    </div>

                    <div>
                        <p className="intro">“We learned how to communicate with empathy and depth.”</p>
                        <p className="dr-norman">Fernando & Mimi Sabatini</p>
                    </div>

                    <div>
                        <p className="intro">“The concepts and practices are clear and effective.”
                        </p>
                        <p className="dr-norman">Victoria & Juan</p>
                    </div>

                    <div>
                        <p className="intro">“The concept of THE ORGANIC SELF revolutionized my paradigms.”</p>
                        <p className="dr-norman">David J. Murphy</p>
                    </div>

                    <div>
                        <p className="intro">“The listening exercises were very  helpful.”</p>
                        <p className="dr-norman">Francis & Peter Young</p>
                    </div>

                    <div>
                        <p className="intro">“Biology before psychology and religion? What a logical idea!”</p>
                        <p className="dr-norman">Benicio Paz</p>
                    </div>

                    <div>
                        <p className="intro">“Emotions deepen my spiritual life”.</p>
                        <p className="dr-norman">Sarah Bustos</p>
                    </div>

                </Slider>
            </div>

        </>
    )
}

export default SectionReviewsEnglish;